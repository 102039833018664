import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {BsacFormsModule} from "@solidev/bsadmincomponents";
import {LabSelectComponent} from "./lab-select/lab-select.component";

@NgModule({
  imports: [CommonModule, NgbModule, BsacFormsModule],
  declarations: [LabSelectComponent],
  exports: [LabSelectComponent]
})
export class LabSelectModule {}
