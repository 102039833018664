import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSDateTimeField,
  DSDefaultField,
  DSForeignKeyField,
  DSManyToManyField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider,
  DSTextField
} from "@solidev/ngdataservice";

export class LabItem extends DSModel {
  @DSAutoField({
    name: "id",
    verboseName: "ID",
    required: false,
    helpText: "",
    choices: []
  })
  public id: number;

  @DSForeignKeyField({
    name: "lab",
    verboseName: "Lab",
    required: false,
    helpText: "",
    choices: []
  })
  public lab: number;

  @DSCharField({
    name: "name",
    verboseName: "Nom",
    required: true,
    helpText: "",
    choices: [],
    maxLength: 255
  })
  public name: string;

  @DSTextField({
    name: "sdesc",
    verboseName: "Description",
    required: false,
    helpText: "",
    choices: [],
    maxLength: 1000
  })
  public sdesc: string;

  @DSCharField({
    name: "barcode",
    verboseName: "Code",
    required: false,
    helpText: "",
    choices: [],
    maxLength: 30
  })
  public barcode: string;

  @DSManyToManyField({
    name: "security",
    verboseName: "Infos de sécurité",
    required: false,
    helpText: "",
    choices: []
  })
  public security: number[];

  @DSManyToManyField({
    name: "providers",
    verboseName: "Fournisseurs",
    required: false,
    helpText: "",
    choices: []
  })
  public providers: number[];

  @DSManyToManyField({
    name: "logs",
    verboseName: "Logs",
    required: false,
    helpText: "",
    choices: []
  })
  public logs: number[];

  @DSManyToManyField({
    name: "storages",
    verboseName: "Emplacements de stockage",
    required: false,
    helpText: "",
    choices: []
  })
  public storages: number[];

  @DSManyToManyField({
    name: "categs",
    verboseName: "Catégories",
    required: false,
    helpText: "",
    choices: []
  })
  public categs: number[];

  @DSManyToManyField({
    name: "medias",
    verboseName: "Médias",
    required: false,
    helpText: "",
    choices: []
  })
  public medias: number[];

  @DSDefaultField({
    name: "security_details",
    verboseName: "Infos de sécurité",
    required: false,
    helpText: "",
    choices: []
  })
  public security_details: any;

  @DSDefaultField({
    name: "providers_details",
    verboseName: "Fournisseurs",
    required: false,
    helpText: "",
    choices: []
  })
  public providers_details: any;

  @DSDefaultField({
    name: "logs_details",
    verboseName: "Logs",
    required: false,
    helpText: "",
    choices: []
  })
  public logs_details: any;

  @DSDefaultField({
    name: "storages_details",
    verboseName: "Emplacements de stockage",
    required: false,
    helpText: "",
    choices: []
  })
  public storages_details: any;

  @DSDefaultField({
    name: "categs_details",
    verboseName: "Catégories",
    required: false,
    helpText: "",
    choices: []
  })
  public categs_details: any;

  @DSDefaultField({
    name: "medias_details",
    verboseName: "Médias",
    required: false,
    helpText: "",
    choices: []
  })
  public medias_details: any;

  @DSTextField({
    name: "comments",
    verboseName: "Commentaires",
    required: false,
    helpText: "",
    choices: [],
    maxLength: 1000
  })
  public comments: string;

  @DSDateTimeField({
    name: "created",
    verboseName: "Date de création",
    required: false,
    helpText: "",
    choices: []
  })
  public created: Date;

  @DSDateTimeField({
    name: "updated",
    verboseName: "Date de mise à jour",
    required: false,
    helpText: "",
    choices: []
  })
  public updated: Date;

  @DSCharField({
    name: "status",
    verboseName: "Etat",
    required: true,
    helpText: "",
    choices: [["OK", "OK"], ["KO", "Pas OK"]],
    maxLength: 4
  })
  public status: string;

  @DSCharField({
    name: "orig_id",
    verboseName: "ID origine",
    required: false,
    helpText: "",
    choices: []
  })
  public orig_id: string;
}

@Injectable({
  providedIn: "root"
})
export class LabItemService extends DSRestCollection<LabItem> {
  public adapter_config = {basePath: "/api/v1/labs/${context.lab}/items"};
  public model = LabItem;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
