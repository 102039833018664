import {CdkTableModule} from "@angular/cdk/table";
import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {BsacApiConstantsModule, BsacFormsModule, BsacModelListModule} from "@solidev/bsadmincomponents";
import {LabExpItemsManageComponent} from "./labexpitems-manage/labexpitems-manage.component";
import {LabExpItemCreateComponent} from "./labexpitem-create/labexpitem-create.component";
import {LabExpItemManageComponent} from "./labexpitem-manage/labexpitem-manage.component";

@NgModule({
  imports: [
    CommonModule,
    CdkTableModule,
    NgbModule,
    BsacApiConstantsModule,
    BsacFormsModule,
    BsacModelListModule,
    RouterModule,
    ReactiveFormsModule
  ],
  providers: [],
  declarations: [LabExpItemsManageComponent, LabExpItemCreateComponent, LabExpItemManageComponent],
  exports: [LabExpItemsManageComponent, LabExpItemCreateComponent, LabExpItemManageComponent]
})
export class LabExpItemModule {
}

