import {PubHomeComponent} from "./views/pub-home/pub-home.component";
import {PubValidGuard} from "./views/common/pub-valid.guard";

export const pubRoutes = [
  {
    path: "", canActivateChild: [PubValidGuard],
    children: [
      {path: "", pathMatch: "full", component: PubHomeComponent},
      {path: "labs", loadChildren: "./views/lab/lab.module#EdslLabModule"}
    ]

  }

];


