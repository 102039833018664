import {CdkTableModule} from "@angular/cdk/table";
import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {BsacApiConstantsModule, BsacFormsModule, BsacModelListModule} from "@solidev/bsadmincomponents";
import {LabItemStoragesManageComponent} from "./labitemstorages-manage/labitemstorages-manage.component";
import {LabItemStorageCreateComponent} from "./labitemstorage-create/labitemstorage-create.component";
import {LabItemStorageManageComponent} from "./labitemstorage-manage/labitemstorage-manage.component";

@NgModule({
  imports: [
    CommonModule,
    CdkTableModule,
    NgbModule,
    BsacApiConstantsModule,
    BsacFormsModule,
    BsacModelListModule,
    RouterModule,
    ReactiveFormsModule
  ],
  providers: [],
  declarations: [LabItemStoragesManageComponent, LabItemStorageCreateComponent, LabItemStorageManageComponent],
  exports: [LabItemStoragesManageComponent, LabItemStorageCreateComponent, LabItemStorageManageComponent]
})
export class LabItemStorageModule {
}

