import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSDateTimeField,
  DSDefaultField,
  DSForeignKeyField,
  DSManyToManyField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider,
  DSTextField
} from "@solidev/ngdataservice";

export class LabExperiment extends DSModel {
  @DSAutoField({
    name: "id",
    verboseName: "ID",
    required: false,
    helpText: "",
    choices: []
  })
  public id: number;

  @DSForeignKeyField({
    name: "lab",
    verboseName: "Lab",
    required: false,
    helpText: "",
    choices: []
  })
  public lab: number;


  @DSForeignKeyField({
    name: "owner",
    verboseName: "Créateur",
    required: false,
    helpText: "Créateur",
    relatedModel: "person.Person",
    remoteField: "id",
    relatedName: "labexperiment_set"
  })
  public owner: number;

  @DSCharField({
    name: "etype",
    verboseName: "Type",
    required: false,
    helpText: "Type d'expérience",
    minLength: 0,
    maxLength: 4,
    choices: [["ELV", "Eleve"], ["TEA", "Prof"], ["LAB", "Laboratoire"], ["EVA", "Evaluation"], ["OTH", "Autre"]]
  })
  public etype: string;


  @DSCharField({
    name: "title",
    verboseName: "Titre",
    required: true,
    helpText: "",
    choices: [],
    maxLength: 150
  })
  public title: string;

  @DSTextField({
    name: "sdesc",
    verboseName: "Description",
    required: false,
    helpText: "",
    choices: [],
    maxLength: 600
  })
  public sdesc: string;

  @DSTextField({
    name: "ldesc",
    verboseName: "Description longue",
    required: false,
    helpText: "",
    choices: [],
    maxLength: 600
  })
  public ldesc: string;


  @DSManyToManyField({
    name: "logs",
    verboseName: "Logs",
    required: false,
    helpText: "",
    choices: []
  })
  public logs: number[];


  @DSManyToManyField({
    name: "categs",
    verboseName: "Catégories",
    required: false,
    helpText: "",
    choices: []
  })
  public categs: number[];

  @DSManyToManyField({
    name: "medias",
    verboseName: "Médias",
    required: false,
    helpText: "",
    choices: []
  })
  public medias: number[];


  @DSDefaultField({
    name: "logs_details",
    verboseName: "Logs",
    required: false,
    helpText: "",
    choices: []
  })
  public logs_details: any;

  @DSDefaultField({
    name: "categs_details",
    verboseName: "Catégories",
    required: false,
    helpText: "",
    choices: []
  })
  public categs_details: any;

  @DSDefaultField({
    name: "medias_details",
    verboseName: "Médias",
    required: false,
    helpText: "",
    choices: []
  })
  public medias_details: any;


  @DSDateTimeField({
    name: "created",
    verboseName: "Date de création",
    required: false,
    helpText: "",
    choices: []
  })
  public created: Date;

  @DSDateTimeField({
    name: "updated",
    verboseName: "Date de mise à jour",
    required: false,
    helpText: "",
    choices: []
  })
  public updated: Date;

  @DSCharField({
    name: "status",
    verboseName: "Etat",
    required: true,
    helpText: "",
    choices: [["OK", "OK"], ["DEP", "Plus à jour"], ["ERR", "Erronée"], ["ARC", "Archivé"]],
    maxLength: 4
  })
  public status: string;

  @DSCharField({
    name: "importid",
    verboseName: "ID origine",
    required: false,
    helpText: "",
    choices: []
  })
  public importid: string;
}

@Injectable({
  providedIn: "root"
})
export class LabExperimentService extends DSRestCollection<LabExperiment> {
  public adapter_config = {basePath: "/api/v1/labs/${context.lab}/experiments/experiments"};
  public model = LabExperiment;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
