import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot, UrlTree} from "@angular/router";
import {Observable} from "rxjs";
import {first, map} from "rxjs/operators";
import {CurrentSiteService} from "./current-site.service";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: "root"
})
export class PubValidGuard implements CanActivate, CanActivateChild {
  constructor(private _csite: CurrentSiteService) {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this._fetch(next, state);
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this._fetch(next, state);
  }


  private _fetch(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const slug = window.location.hostname.replace(environment.BASE_DOMAIN, "");
    return this._csite.fetch(slug).pipe(first(),
      map(() => {
        return this._csite.school !== undefined;
      })
    );
  }

}
