import {LabItemCreateComponent} from "./labitem-create/labitem-create.component";
import {LabItemDeleteComponent} from "./labitem-delete/labitem-delete.component";
import {LabItemListComponent} from "./labitem-list/labitem-list.component";
import {LabItemManageComponent} from "./labitem-manage/labitem-manage.component";
import {LabItemSelectModule} from "./labitem-select.module";
import {CdkTableModule} from "@angular/cdk/table";
import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {BsacApiConstantsModule, BsacFormsModule, BsacModelListModule} from "@solidev/bsadmincomponents";
import {LabItemStorageModule} from "../labitemstorage/labitemstorage.module";
import {LabItemLogModule} from "../labitemlog/labitemlog.module";
import {LabItemProviderModule} from "../labitemprovider/labitemprovider.module";
import {EdsCommonModule} from "../../common/common.module";
import {LabItemMediaModule} from "../labitemmedia/labitemmedia.module";

@NgModule({
  imports: [
    LabItemSelectModule,
    CommonModule,
    CdkTableModule,
    NgbModule,
    BsacApiConstantsModule,
    BsacFormsModule,
    BsacModelListModule,
    RouterModule,
    ReactiveFormsModule,
    LabItemStorageModule,
    LabItemLogModule,
    LabItemProviderModule,
    LabItemMediaModule,
    EdsCommonModule
  ],
  providers: [],
  declarations: [LabItemCreateComponent, LabItemListComponent, LabItemDeleteComponent, LabItemManageComponent],
  exports: [
    LabItemCreateComponent,
    LabItemListComponent,
    LabItemDeleteComponent,
    LabItemManageComponent,
    LabItemSelectModule
  ]
})
export class LabItemModule {
}
