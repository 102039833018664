import {LabExperimentCreateComponent} from "./labexperiment-create/labexperiment-create.component";
import {LabExperimentDeleteComponent} from "./labexperiment-delete/labexperiment-delete.component";
import {LabExperimentListComponent} from "./labexperiment-list/labexperiment-list.component";
import {LabExperimentManageComponent} from "./labexperiment-manage/labexperiment-manage.component";
import {LabExperimentSelectModule} from "./labexperiment-select.module";
import {CdkTableModule} from "@angular/cdk/table";
import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {BsacApiConstantsModule, BsacFormsModule, BsacModelListModule} from "@solidev/bsadmincomponents";
import {EdsCommonModule} from "../../common/common.module";
import {LabExpMediaModule} from "../labexpmedia/labexpmedia.module";
import {LabExpItemModule} from "../labexpitem/labexpitem.module";

@NgModule({
  imports: [
    LabExperimentSelectModule,
    CommonModule,
    CdkTableModule,
    NgbModule,
    BsacApiConstantsModule,
    BsacFormsModule,
    BsacModelListModule,
    RouterModule,
    ReactiveFormsModule,
    LabExpMediaModule,
    LabExpItemModule,
    EdsCommonModule
  ],
  providers: [],
  declarations: [
    LabExperimentCreateComponent,
    LabExperimentListComponent,
    LabExperimentDeleteComponent,
    LabExperimentManageComponent
  ],
  exports: [
    LabExperimentCreateComponent,
    LabExperimentListComponent,
    LabExperimentDeleteComponent,
    LabExperimentManageComponent,
    LabExperimentSelectModule
  ]
})
export class LabExperimentModule {
}
