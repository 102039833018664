import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {BsacFormsModule} from "@solidev/bsadmincomponents";
import {LabExpCategorySelectComponent} from "./labexpcategory-select/labexpcategory-select.component";

@NgModule({
  imports: [CommonModule, NgbModule, BsacFormsModule],
  declarations: [LabExpCategorySelectComponent],
  exports: [LabExpCategorySelectComponent]
})
export class LabExpCategorySelectModule {}
