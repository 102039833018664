import {LabExperiment, LabExperimentService} from "./labexperiment.service";
import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {Observable} from "rxjs";

@Injectable({
  providedIn: "root"
})
export class LabExperimentResolve implements Resolve<LabExperiment> {
  constructor(private _model: LabExperimentService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<LabExperiment> {
    return this._model.get(+route.params.labexperimentId,
      {context: {lab: +route.params.labId}});
  }
}
