import {LabCategory, LabCategoryService} from "./labcategory.service";
import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {Observable} from "rxjs";

@Injectable({
  providedIn: "root"
})
export class LabCategoryResolve implements Resolve<LabCategory> {
  constructor(private _model: LabCategoryService) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<LabCategory> {
    return this._model.get(+route.params["labcategoryId"],
      {context: {lab: +route.params["labId"]}});
  }
}
