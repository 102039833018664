import {LabProviderCreateComponent} from "./labprovider-create/labprovider-create.component";
import {LabProviderDeleteComponent} from "./labprovider-delete/labprovider-delete.component";
import {LabProviderListComponent} from "./labprovider-list/labprovider-list.component";
import {LabProviderManageComponent} from "./labprovider-manage/labprovider-manage.component";
import {LabProviderSelectModule} from "./labprovider-select.module";
import {CdkTableModule} from "@angular/cdk/table";
import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {BsacApiConstantsModule, BsacFormsModule, BsacModelListModule} from "@solidev/bsadmincomponents";
import {NgPipesModule} from "ngx-pipes";

@NgModule({
  imports: [
    LabProviderSelectModule,
    CommonModule,
    CdkTableModule,
    NgbModule,
    BsacApiConstantsModule,
    BsacFormsModule,
    BsacModelListModule,
    RouterModule,
    ReactiveFormsModule,
    NgPipesModule
  ],
  providers: [],
  declarations: [
    LabProviderCreateComponent,
    LabProviderListComponent,
    LabProviderDeleteComponent,
    LabProviderManageComponent
  ],
  exports: [
    LabProviderCreateComponent,
    LabProviderListComponent,
    LabProviderDeleteComponent,
    LabProviderManageComponent,
    LabProviderSelectModule
  ]
})
export class LabProviderModule {
}
