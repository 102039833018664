import {Injectable} from "@angular/core";
import {
  DSAutoField, DSBooleanField, DSCharField, DSForeignKeyField, DSModel, DSRestCollection,
  DSRestCollectionSetupProvider, DSEmailField, DSDateTimeField, DSPasswordField
} from "@solidev/ngdataservice";

export class User extends DSModel {
  @DSAutoField()
  public id: number;
  @DSCharField()
  public login: string;

  @DSBooleanField()
  public is_active: boolean;
  @DSBooleanField()
  public is_superuser: boolean;
  @DSBooleanField()
  public is_staff: boolean;
  @DSCharField()
  public firstname: string;
  @DSCharField()
  public lastname: string;
  @DSCharField()
  public title: string;
  @DSEmailField({validateEmail: true})
  public email: string;
  @DSCharField()
  public phone: string;
  @DSCharField()
  public mobile: string;
  @DSPasswordField()
  public password: string;
  @DSDateTimeField()
  public last_login: Date;
  @DSPasswordField()
  public defaultpw: string;
  @DSForeignKeyField()
  public photo: number;
  @DSForeignKeyField()
  public images: number[];
  @DSCharField()
  public aduserid: string;
  @DSCharField()
  public adusergroup: string;

}

@Injectable({
  providedIn: "root"
})
export class UserService extends DSRestCollection<User> {
  public adapter_config = {basePath: "/api/v1/users"};
  public model = User;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
