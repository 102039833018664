import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider
} from "@solidev/ngdataservice";

export class Lab extends DSModel {
  @DSAutoField({
    name: "id",
    verboseName: "ID",
    required: false,
    helpText: "",
    choices: []
  })
  public id: number;

  @DSCharField({
    name: "name",
    verboseName: "Nom court du laboratoire",
    required: true,
    helpText: "",
    choices: [],
    maxLength: 30
  })
  public name: string;

  @DSCharField({
    name: "fullname",
    verboseName: "Nom complet du laboratoire",
    required: true,
    helpText: "",
    choices: [],
    maxLength: 150
  })
  public fullname: string;

  @DSCharField({
    name: "status",
    verboseName: "",
    required: false,
    helpText: "",
    choices: [["OK", "OK"], ["KO", "Pas OK"]],
    maxLength: 4
  })
  public status: string;
}

@Injectable({
  providedIn: "root"
})
export class LabService extends DSRestCollection<Lab> {
  public adapter_config = {basePath: "/api/v1/labs"};
  public model = Lab;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
