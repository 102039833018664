import {CdkTableModule} from "@angular/cdk/table";
import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {BsacApiConstantsModule, BsacFormsModule, BsacModelListModule} from "@solidev/bsadmincomponents";
import {LabItemProvidersManageComponent} from "./labitemproviders-manage/labitemproviders-manage.component";
import {LabItemProviderCreateComponent} from "./labitemprovider-create/labitemprovider-create.component";
import {LabItemProviderManageComponent} from "./labitemprovider-manage/labitemprovider-manage.component";

@NgModule({
  imports: [
    CommonModule,
    CdkTableModule,
    NgbModule,
    BsacApiConstantsModule,
    BsacFormsModule,
    BsacModelListModule,
    RouterModule,
    ReactiveFormsModule
  ],
  providers: [],
  declarations: [LabItemProvidersManageComponent, LabItemProviderManageComponent, LabItemProviderCreateComponent],
  exports: [LabItemProvidersManageComponent, LabItemProviderManageComponent, LabItemProviderCreateComponent]
})
export class LabItemProviderModule {
}
