import {Injectable} from "@angular/core";
import * as moment_ from "moment";

const moment = moment_;

export interface IDateInterval {
  start: moment_.Moment;
  end: moment_.Moment;
}

@Injectable()
export class DatesService {
  public getCurrentYear(): IDateInterval {
    let start, end: moment_.Moment;
    if (moment().month() <= 7) {
      start = moment().year(moment().year() - 1).month(8).date(1);
      end = moment().month(6).day(15);
    } else {
      start = moment().month(8).day(1);
      end = moment().year(moment().year() + 1).month(6).date(15);
    }
    return {start: start, end: end};
  }

  public getNextYear(): IDateInterval {
    const cur = this.getCurrentYear();
    const start = cur.start.add(moment.duration({years: 1}));
    const end = cur.end.clone().add(moment.duration({years: 1}));
    return {start: start, end: end};
  }

  public getCurrentYearT1(): IDateInterval {
    const start: moment_.Moment = this.getCurrentYear().start;
    const end = start.clone().add(moment.duration({weeks: 14}));
    console.log(start, end);
    return {start: start, end: end};
  }

  public getCurrentYearT2(): IDateInterval {
    const start: moment_.Moment = this.getCurrentYear().start.add(moment.duration({weeks: 15}));
    const end = start.clone().add(moment.duration({months: 15}));
    return {start: start, end: end};
  }

  public getCurrentYearT3(): IDateInterval {
    const start: moment_.Moment = this.getCurrentYear().start.add(moment.duration({weeks: 30}));
    const end = start.clone().add(moment.duration({weeks: 15}));
    return {start: start, end: end};
  }

  public getCurrentYearS1(): IDateInterval {
    const start: moment_.Moment = this.getCurrentYear().start;
    const end = start.clone().add(moment.duration({weeks: 22}));
    return {start: start, end: end};
  }

  public getCurrentYearS2(): IDateInterval {
    const start: moment_.Moment = this.getCurrentYear().start.add(moment.duration({weeks: 22}));
    const end = start.clone().add(moment.duration({months: 23}));
    return {start: start, end: end};
  }

}
