import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSForeignKeyField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider
} from "@solidev/ngdataservice";


export class SectionField extends DSModel {
  @DSAutoField({
    name: "id",
    verboseName: "ID",
    required: false,
    helpText: "",
    choices: []
  })
  public id: number;


  @DSForeignKeyField({
    name: "section",
    verboseName: "Section",
    required: false,
    helpText: "Section",
    relatedModel: "SectionPart",
    remoteField: "id",
    relatedName: "fields"
  })
  public section: number;

  @DSForeignKeyField({
    name: "template_part_field",
    verboseName: "Template part field",
    required: false,
    helpText: "Template part field",
    relatedModel: "TemplatePartField",
    remoteField: "id",
    relatedName: "sectionpartfields"
  })
  public template_part_field: number;

  @DSCharField({
    name: "txt_value",
    verboseName: "Text value",
    required: false,
    helpText: "",
    minLength: 0,
    maxLength: 2000000,
    choices: []
  })
  public txt_value: string;


}

@Injectable({
  providedIn: "root"
})
export class SectionFieldService extends DSRestCollection<SectionField> {
  public adapter_config = {basePath: "/api/v1/sectionfields"};
  public model = SectionField;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
