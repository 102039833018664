import {HttpClient} from "@angular/common/http";
import {Inject, Injectable, InjectionToken} from "@angular/core";
import {DS_REST_BACKEND_AUTHENTICATOR, DS_REST_BACKEND_CONFIG, IDSAuthentication, IDSRestBackendConfig} from "@solidev/ngdataservice";
import {indexOf, intersection} from "lodash-es";

import {Observable, of} from "rxjs";
import {Token, TokenService} from "./token.service";
import {User, UserService} from "./user.service";
import {catchError, map} from "rxjs/operators";

/**
 * Localstorage injection token
 */
export let LOCAL_STORAGE_OBJECT = new InjectionToken("localstorage");

/**
 * Authentication message.
 */
export interface IAuthMessage {
  authenticated: boolean;
  message?: string;
  error?: number;
}


/**
 * Authentication service.
 * Provides login, logout, autologin features.
 * @SEE: remove those |null when https://github.com/angular/angular-cli/issues/2034#issuecomment-302666897 works
 */
@Injectable({
  providedIn: "root"
})
export class AuthService {
  public user: User;
  public token: Token;
  public version: string;

  // FIXME: use some typing for Storage object (see lvadg-client-next)
  constructor(@Inject(LOCAL_STORAGE_OBJECT) public storage: any,
              @Inject(DS_REST_BACKEND_CONFIG) public backendConfig: IDSRestBackendConfig | null,
              @Inject(DS_REST_BACKEND_AUTHENTICATOR) public backendAuth: IDSAuthentication | null,
              public _users: UserService,
              public _tokens: TokenService,
              public _http: HttpClient) {
    this.setAnonymousUser();
  }

  private _redirectUrl: string;

  /**
   * Redirect URL setter
   * Checks that url matches domain
   */
  public set redirectUrl(url: string) {
    console.log(window.location.hostname, window.location.host);
    this._redirectUrl = url;
  }

  /**
   * Return true if user is authenticated.
   * Authenticated user is known, with a valid (not expired) token, and is_active status.
   */
  public get isAuthenticated(): boolean {
    // FIXME: check token expire date
    return ((this.user !== null) && (this.token !== null) && (this.user.is_active));
  }

  /**
   * Return true if user is superuser.
   * Superuser have is_superuser true, and his token scope have superuser set.
   */
  public get isSuperUser(): boolean {
    return this.isAuthenticated && indexOf(this.token.scopes, "superuser") >= 0 && this.user.is_superuser;
  }

  /**
   * Reset authentication.
   */
  public setAnonymousUser(): void {
    this.user = null;
    this.token = null;
    this.version = null;
    this.backendAuth.anonymous();
  }

  /**
   * Tries to log user in using username, password.
   * @param  username
   * @param  password
   */
  public userPassLogin(username: string, password: string): Observable<IAuthMessage> {
    return this.authApiCall({username: username, password: password});
  }

  /**
   * Tries to log user in using token.
   * @param  token
   * @returns  authentication result
   */
  public tokenLogin(token: string): Observable<IAuthMessage> {
    return this.authApiCall({token: token});
  }

  /**
   * Tries to authenticate user from stored Authentication data.
   * @param  force authentication from stored data
   */
  public autologin(force: boolean = false): Observable<IAuthMessage> {
    console.log("Trying autologin, with force status", force);
    if (force || !this.isAuthenticated) {
      this.retrieve();

      console.log("Retrieved auth : ", this, this.isAuthenticated);
      if (this.isAuthenticated) {
        this.backendAuth.authenticate(this.token.token);
        if (force) {
          return this.tokenLogin(this.token.token).pipe(
            map((authresult) => {
              return authresult;
            }));
        } else {
          return of({authenticated: true});
        }
      } else {
        this.setAnonymousUser();
        this.persist();
        return of({authenticated: false});
      }
    } else {
      return of({authenticated: true});
    }
  }

  /**
   * Logs user out.
   */
  public logout(): void {
    this.setAnonymousUser();
    this.persist();
    // Broadcast user:logout somewhere
  }

  /**
   * Redirect URL getter
   * Return to homepage if empty or null
   */
  public getRedirectUrl(): Observable<string> {
    if (!this._redirectUrl) {
      return of("/");
    } else {
      return of(this._redirectUrl);
    }
  }

  /**
   * Save authentication infos to local storage.
   * @todo NOT IMPLEMENTED
   */
  protected persist(): void {
    console.log("Storing data to localstorage", this.user, this.token, this.version);
    this.storage.setItem("Authentication", JSON.stringify({user: this.user, token: this.token, version: this.version}));
    console.log("Storage done", this.storage);
  }

  /**
   * Load authentication infos from local storage.
   * @todo NOT IMPLEMENTED
   */
  protected retrieve(): AuthService {
    if (this.isAuthenticated) {
      return this;
    }
    if (this.storage.getItem("Authentication")) {
      console.log("Localstorage data found, trying to authenticate");
      const auth = JSON.parse(this.storage.getItem("Authentication"));
      console.log("Auth data from storage", auth);
      if (auth && auth.user && auth.token) {
        this.user = new User(this._users, auth.user);
        this.token = new Token(this._tokens, auth.token);
        this.version = auth.version;
        // Broadcast user:login message somewhere ?
        console.log("Retrieval done");
        return this;
      }
    }
    console.log("No valid localstorage data found");
    // Broadcast user:logout message somewhere ?
    this.setAnonymousUser();
    return this;

  }

  /**
   * Calls authentication api endpoint.
   * @param auth_body auth payload
   */
  protected authApiCall(auth_body: any): Observable<IAuthMessage> {
    // FIXME: use backend !!
    console.log("API call with", auth_body);
    return this._http.post<any>(this.backendConfig.url + "/api/v1/auth", auth_body)
      .pipe(
        map((result) => {
          this.token = new Token(this._tokens, result.token);
          this.user = new User(this._users, result.user);
          this.version = "";
          this.persist();
          this.backendAuth.authenticate(this.token.token);
          // Broadcast user:login to somewhere
          console.log("Authentication done");
          return {authenticated: true};
        }),
        catchError((err, caught) => {
          console.log("No authentication, clearing data");
          this.setAnonymousUser();
          this.persist();
          console.log(err);
          const errbody = err.error;
          if (errbody.error) {
            // Broadcast user:login to somewhere
            return of({authenticated: false, message: errbody.error, error: err.status});
          } else {
            return of({authenticated: false, message: "Unknown error", error: err.status});
          }
        }));
  }


}
