import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSDateTimeField,
  DSDefaultField,
  DSIntegerField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider,
  DSTextField
} from "@solidev/ngdataservice";


export class School extends DSModel {
  @DSAutoField({
    name: "id",
    verboseName: "ID",
    required: false,
    helpText: "",
    choices: []
  })
  public id: number;

  @DSCharField({
    name: "name",
    verboseName: "Nom",
    required: false,
    helpText: "Nom de l'établissement",
    minLength: 0,
    maxLength: 200,
    choices: []
  })
  public name: string;

  @DSCharField({
    name: "slug",
    verboseName: "Sous-domaine",
    required: false,
    helpText: "Sous domaine établissement",
    minLength: 0,
    maxLength: 200,
    choices: []
  })
  public slug: string;


  // Tracking mixin
  @DSIntegerField({
    name: "orig_id",
    verboseName: "Orig id",
    required: false,
    helpText: "",
    choices: []
  })
  public orig_id: number;


  @DSCharField({
    name: "status",
    verboseName: "Etat",
    required: false,
    helpText: "",
    default: "NOK",
    choices: [
      ["OK", "OK"],
      ["NOK", "Not OK"]
    ],
    maxLength: 10
  })
  public status: string;

  @DSTextField({
    name: "comments",
    verboseName: "Commentaires (interne)",
    required: false,
    helpText: "",
    choices: [],
    maxLength: 10000
  })
  public comments: string;

  @DSDefaultField({
    name: "flags",
    verboseName: "Flags",
    required: false,
    helpText: "",
    choices: []
  })
  public flags: any;

  @DSDateTimeField({
    name: "created",
    verboseName: "Création",
    required: false,
    helpText: "",
    choices: []
  })
  public created: Date;

  @DSDateTimeField({
    name: "updated",
    verboseName: "Mise à jour",
    required: false,
    helpText: "",
    choices: []
  })
  public updated: Date;


}

@Injectable({
  providedIn: "root"
})
export class SchoolService extends DSRestCollection<School> {
  public adapter_config = {basePath: "/api/v1/schools"};
  public model = School;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
