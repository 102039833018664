import {NgModule} from "@angular/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {IntervalPickerComponent} from "./dates/intervalpicker/intervalpicker.component";
import {ElasticTextareaDirective} from "./input/elastic.directive";
import {MathJaxDirective} from "./math/mathjax.directive";
import {DatesService} from "./dates/dates.service";
import {UploadFileComponent} from "./upload-file/upload-file.component";
import {NgxUploaderModule} from "ngx-uploader";
import {CommonModule} from "@angular/common";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {SafeStylePipe} from "./pipes/safe-style.pipe";
import {SafeHtmlPipe} from "./pipes/safe-html.pipe";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxUploaderModule,
    NgbModule
  ],
  declarations: [
    IntervalPickerComponent,
    ElasticTextareaDirective,
    MathJaxDirective,
    UploadFileComponent,
    SafeStylePipe,
    SafeHtmlPipe
  ],
  exports: [
    IntervalPickerComponent,
    ElasticTextareaDirective,
    MathJaxDirective,
    UploadFileComponent,
    SafeStylePipe,
    SafeHtmlPipe
  ],
  providers: [DatesService]
})
export class EdsCommonModule {
}
