import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSForeignKeyField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider,
  DSTextField
} from "@solidev/ngdataservice";

export class LabProvider extends DSModel {
  @DSAutoField({
    name: "id",
    verboseName: "ID",
    required: false,
    helpText: "",
    choices: []
  })
  public id: number;

  @DSForeignKeyField({
    name: "lab",
    verboseName: "Lab",
    required: false,
    helpText: "",
    choices: []
  })
  public lab: number;

  @DSCharField({
    name: "name",
    verboseName: "Nom du fournisseur",
    required: true,
    helpText: "",
    choices: [],
    maxLength: 100
  })
  public name: string;

  @DSTextField({
    name: "contact",
    verboseName: "Informations de contact",
    required: false,
    helpText: "",
    choices: [],
    maxLength: 4000
  })
  public contact: string;

  @DSCharField({
    name: "url",
    verboseName: "Site internet",
    required: false,
    helpText: "",
    choices: [],
    maxLength: 200
  })
  public url: string;

  @DSCharField({
    name: "status",
    verboseName: "Etat",
    required: true,
    helpText: "",
    choices: [["OK", "OK"], ["KO", "Pas OK"]],
    maxLength: 4
  })
  public status: string;
}

@Injectable({
  providedIn: "root"
})
export class LabProviderService extends DSRestCollection<LabProvider> {
  public adapter_config = {basePath: "/api/v1/labs/${context.lab}/providers"};
  public model = LabProvider;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}

