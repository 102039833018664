import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {DocumentManageComponent} from "./document-manage/document-manage.component";
import {DocumentDisplayComponent} from "./document-display/document-display.component";
import {ReactiveFormsModule} from "@angular/forms";
import {BsacFormsModule} from "@solidev/bsadmincomponents";
import {EdsCommonModule} from "../../../common/common.module";
import {TemplatepartModule} from "../../templates/templatepart/templatepart.module";
import {DocumentCreateComponent} from "./document-create/document-create.component";


@NgModule({
  declarations: [DocumentManageComponent, DocumentDisplayComponent, DocumentCreateComponent],
  exports: [DocumentManageComponent, DocumentDisplayComponent, DocumentCreateComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    BsacFormsModule,
    EdsCommonModule,
    TemplatepartModule
  ]
})
export class DocumentModule {
}
