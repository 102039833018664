import {ProcessResultSectionPart} from "../../process-result.service";

export class DefaultProcessor {
  public process(data: ProcessResultSectionPart, edit: boolean = false): string {
    if (!edit) {
      return data.part.style + data.part.source;
    } else {
      return data.part.style + data.part.source;
    }
  }

}
