import {Component, OnInit} from "@angular/core";
import {CurrentSiteService} from "../common/current-site.service";

@Component({
  selector: "edp-pub-home",
  templateUrl: "./pub-home.component.pug",
  styleUrls: ["./pub-home.component.sass"]
})
export class PubHomeComponent implements OnInit {

  constructor(public csite: CurrentSiteService) {
  }

  ngOnInit() {
  }

}
