import {version} from "./version";
declare var require: any;
export const environment = {
  production: true,
  API_URL: "https://api.educ.space",
  VERSION: version,
  BUILD: require("raw-loader!./build.txt"),
  MODE: "production",
  TOKEN: "i8Fjnd0WvaxTvK7m2EtNiqWUj10jXILVFhTmqnjSOlmNpltmGrjsH5AR6GsmsDk6",
  BASE_DOMAIN: ".lab.educ.space"
};
