import {CdkTableModule} from "@angular/cdk/table";
import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {BsacApiConstantsModule, BsacFormsModule, BsacModelListModule} from "@solidev/bsadmincomponents";
import {LabItemMediaCreateComponent} from "./labitemmedia-create/labitemmedia-create.component";
import {LabItemMediaManageComponent} from "./labitemmedia-manage/labitemmedia-manage.component";
import {LabItemMediasManageComponent} from "./labitemmedias-manage/labitemmedias-manage.component";
import {EdsCommonModule} from "../../common/common.module";
import {NgMathPipesModule} from "ngx-pipes";

@NgModule({
  imports: [
    CommonModule,
    CdkTableModule,
    NgbModule,
    BsacApiConstantsModule,
    BsacFormsModule,
    BsacModelListModule,
    RouterModule,
    ReactiveFormsModule,
    EdsCommonModule,
    NgMathPipesModule
  ],
  providers: [],
  declarations: [
    LabItemMediaCreateComponent,
    LabItemMediaManageComponent,
    LabItemMediasManageComponent],
  exports: [
    LabItemMediaCreateComponent,
    LabItemMediaManageComponent,
    LabItemMediasManageComponent]
})
export class LabItemMediaModule {
}
