import {BrowserModule} from "@angular/platform-browser";
import {NgModule} from "@angular/core";

import {AppComponent} from "./app.component";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {DS_REST_BACKEND_CONFIG, DSRestBackend, DSRestModule} from "@solidev/ngdataservice";
import {BsacMessageModule} from "@solidev/bsadmincomponents";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {environment} from "../environments/environment";
import {PubHomeComponent} from "./views/pub-home/pub-home.component";
import {RouterModule} from "@angular/router";
import {pubRoutes} from "./app.routing";
import {LOCAL_STORAGE_OBJECT} from "educspace";

@NgModule({
  declarations: [
    AppComponent,
    PubHomeComponent
  ],
  exports: [
    PubHomeComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    DSRestModule,
    BsacMessageModule,
    NgbModule,
    RouterModule.forRoot(pubRoutes, { relativeLinkResolution: 'legacy' })
  ],
  providers: [
    DSRestBackend,
    {
      provide: LOCAL_STORAGE_OBJECT,
      useValue: localStorage
    },
    {
      provide: DS_REST_BACKEND_CONFIG,
      useValue: {
        url: environment.API_URL,
        headers: {
          "x-token": environment.TOKEN
        }
      }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
