import {Injectable} from "@angular/core";
// noinspection ES6PreferShortImport
import {TemplatePart, TemplatePartService} from "../../templates/templatepart/templatepart.service";
// noinspection ES6PreferShortImport
import {TemplatePartField, TemplatePartFieldService} from "../../templates/templatepartfield/templatepartfield.service";
// noinspection ES6PreferShortImport
import {SectionField, SectionFieldService} from "../sectionpartfield/sectionpartfield.service";
// noinspection ES6PreferShortImport
import {Section, SectionService} from "../section/section.service";
import {get_processor} from "./processors";


export class ProcessResultSectionPart {
  public part: TemplatePart;
  public fields: { template_part_field: TemplatePartField; section_field: SectionField; value: any }[];
  private __display: string;
  private __doc: HTMLDocument;

  public display(): string {
    if (this.__display) {
      return this.__display;
    }
    const processor = get_processor(this.part.processor);
    this.__display = this.part.style;
    return this.__display;
  }

  public doc(): HTMLDocument {
    if (this.__doc) {
      return this.__doc;
    }
    const processor = get_processor(this.part.processor);
    this.__display = processor.process(this);
    const parser = new DOMParser();
    this.__doc = parser.parseFromString(this.__display, "text/html");
    console.log(this.__doc);
    return this.__doc;
  }


}


export class ProcessResultSection {
  public section: Section;
  public parts: ProcessResultSectionPart[];

  public display(): string {
    const out: string[] = [];
    for (const part of this.parts) {
      out.push(part.display());
    }
    return out.join("");
  }
}

@Injectable({
  providedIn: "root"
})
export class ProcessResultService {
  public sections: ProcessResultSection[];

  constructor(public _sections: SectionService,
              public _sectionfields: SectionFieldService,
              public _templateparts: TemplatePartService,
              public _templatepartfields: TemplatePartFieldService) {
  }

  public fromJson(data: ProcessResultSection[]): ProcessResultSection[] {
    const out: ProcessResultSection[] = [];

    for (const s of data) {
      const so = new ProcessResultSection();
      so.section = new Section(this._sections, s.section);
      so.parts = [];
      for (const p of s.parts) {
        const prps = new ProcessResultSectionPart();
        prps.part = new TemplatePart(this._templateparts, p.part);
        prps.fields = [];
        for (const f of p.fields) {
          prps.fields.push({
            template_part_field: new TemplatePartField(this._templatepartfields, f.template_part_field),
            section_field: new SectionField(this._sections, f.section_field),
            value: f.value
          });
        }
        so.parts.push(prps);
      }
      out.push(so);
    }
    return out;
  }


}
