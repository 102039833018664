import {Component, Input, OnInit} from "@angular/core";
import {ProcessResultSection, ProcessResultSectionPart} from "../../../documents/document/process-result.service";

@Component({
  selector: "eds-template-item",
  templateUrl: "./template-item.component.pug",
  styleUrls: ["./template-item.component.sass"]
})
export class TemplateItemComponent implements OnInit {

  @Input() item: ChildNode;
  @Input() part: ProcessResultSectionPart;
  @Input() sect: ProcessResultSection;

  constructor() {
  }

  ngOnInit(): void {
    console.log(this.item);
  }

}
