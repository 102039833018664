import {LabItem, LabItemService} from "./labitem.service";
import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {Observable} from "rxjs";

@Injectable({
  providedIn: "root"
})
export class LabItemResolve implements Resolve<LabItem> {
  constructor(private _model: LabItemService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<LabItem> {
    return this._model.get(+route.params.labitemId,
      {context: {lab: +route.params.labId}});
  }
}
