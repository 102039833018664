import {Injectable} from "@angular/core";
import {Lab, LabService, School, SchoolService} from "educspace";
import {Observable, of, ReplaySubject} from "rxjs";
import {first, map, switchMap} from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class CurrentSiteService {
  public school: School;
  // public department: Departments[];
  public labs: Lab[];

  private _fetch: ReplaySubject<void>;

  constructor(private _schools: SchoolService, private _labs: LabService) {
  }

  public fetch(slug: string): Observable<void> {
    if (!this._fetch) {
      this._fetch = new ReplaySubject<void>(1);
      console.log("Fetching school data");
      this._schools.queryset.query({slug: slug}).get()
        .pipe(
          first(),
          switchMap((schools) => {
            if (schools.items.length === 1) {
              this.school = schools.items[0];
              return this._labs.queryset.query({school: this.school.id}).get().pipe(first());
            } else {
              return of({items: []});
            }
          }),
          map((result) => {
              if (result.items.length === 0) {
                this.labs = [];
              } else {
                this.labs = result.items;
              }
            }
          )
        )
        .subscribe(() => {
          this._fetch.next();
        });
    }
    return this._fetch.asObservable();
  }

}
