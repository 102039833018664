import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {BsacFormsModule} from "@solidev/bsadmincomponents";
import {LabExperimentSelectComponent} from "./labexperiment-select/labexperiment-select.component";

@NgModule({
  imports: [CommonModule, NgbModule, BsacFormsModule],
  declarations: [LabExperimentSelectComponent],
  exports: [LabExperimentSelectComponent]
})
export class LabExperimentSelectModule {}
