import {CdkTableModule} from "@angular/cdk/table";
import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {BsacApiConstantsModule, BsacFormsModule, BsacModelListModule} from "@solidev/bsadmincomponents";
import {LabExpMediaCreateComponent} from "./labexpmedia-create/labexpmedia-create.component";
import {LabExpMediaManageComponent} from "./labexpmedia-manage/labexpmedia-manage.component";
import {LabExpMediasManageComponent} from "./labexpmedias-manage/labexpmedias-manage.component";
import {EdsCommonModule} from "../../common/common.module";
import {NgMathPipesModule} from "ngx-pipes";

@NgModule({
  imports: [
    CommonModule,
    CdkTableModule,
    NgbModule,
    BsacApiConstantsModule,
    BsacFormsModule,
    BsacModelListModule,
    RouterModule,
    ReactiveFormsModule,
    EdsCommonModule,
    NgMathPipesModule
  ],
  providers: [],
  declarations: [
    LabExpMediaCreateComponent,
    LabExpMediaManageComponent,
    LabExpMediasManageComponent],
  exports: [
    LabExpMediaCreateComponent,
    LabExpMediaManageComponent,
    LabExpMediasManageComponent]
})
export class LabExpMediaModule {
}
