import {Component, Input, OnInit} from "@angular/core";
import {Document} from "../document.service";
import {ProcessResultService} from "../process-result.service";

@Component({
  selector: "eds-document-display",
  templateUrl: "./document-display.component.pug",
  styleUrls: ["./document-display.component.sass"]
})
export class DocumentDisplayComponent implements OnInit {
  @Input() public document: Document | null = null;

  constructor(private _prs: ProcessResultService) {
  }

  ngOnInit(): void {
    this.document.processed = this._prs.fromJson(this.document.processed);
  }

}
