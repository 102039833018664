import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSForeignKeyField,
  DSIntegerField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider,
  DSTextField,
  IDSModelClass
} from "@solidev/ngdataservice";

/*
    evaluation = models.ForeignKey(Evaluation, related_name="items",
                                   on_delete=models.CASCADE)
    parent = TreeForeignKey('self', null=True, blank=True,
                            related_name='children', db_index=True,
                            on_delete=models.CASCADE)
    type = models.CharField(max_length=3,
                            choices=EVALUATIONITEM_TYPES,
                            db_index=True)
    mode = models.CharField(max_length=2,
                            choices=EVALUATIONITEM_MODES,
                            db_index=True)
    position = models.IntegerField(default=0)

 */


export const EVALUATIONITEM_TYPES_FULL = [
  {"value": "HPT", "description": "Partie", "type": "H"},
  {"value": "HPB", "description": "Problème", "type": "H"},
  {"value": "HEX", "description": "Exercice", "type": "H"},
  {"value": "HHD", "description": "Titre", "type": "H"},
  {"value": "CQS", "description": "Question", "type": "C"},
  {"value": "CSQ", "description": "Sous question", "type": "C"},
  {"value": "CTX", "description": "Texte", "type": "C"},
  {"value": "CDC", "description": "Document", "type": "C"},
  {"value": "QNI", "description": "QCM choix unique", "type": "Q"},
  {"value": "QNI", "description": "QCM choix multiple", "type": "Q"},
  {"value": "CEL", "description": "Elément", "type": "C"},
  {"value": "CNN", "description": "Liste non numérotée", "type": "C"},
];

export const EVALUATIONITEM_TYPES = [];
for (const e of EVALUATIONITEM_TYPES_FULL) {
  EVALUATIONITEM_TYPES.push([e.value, e.description]);
}

export class Evaluationitem extends DSModel {
  @DSAutoField({
    name: "id",
    verboseName: "ID"
  })
  public id: number;

  @DSForeignKeyField({
    name: "evaluation",
    verboseName: "ID Evaluation"
  })
  public evaluation: number;

  @DSForeignKeyField({
    name: "parent",
    verboseName: "ID parent"
  })
  public parent: number;


  @DSCharField({
    name: "type",
    verboseName: "Type",
    required: true,
    choices: EVALUATIONITEM_TYPES
  })
  public type: string;


  @DSCharField({
    name: "mode",
    verboseName: "Mode",
    required: true,
    choices: [
      ["EN", "Enoncé"],
      ["CO", "Correction"]
    ]
  })
  public mode: string;

  @DSTextField({
    name: "content_src",
    verboseName: "Contenu"
  })
  public content_src: string;

  public level: number;
  public lft: number;
  public rght: number;
  public tree_id: number;
}

@Injectable({
  providedIn: "root"
})
export class EvaluationitemService extends DSRestCollection<Evaluationitem> {
  public model: IDSModelClass<Evaluationitem> = Evaluationitem;
  public adapter_config: any = {basePath: "/api/v1/evalitems"};

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
