import {LabExpCategoryCreateComponent} from "./labexpcategory-create/labexpcategory-create.component";
import {LabExpCategoryDeleteComponent} from "./labexpcategory-delete/labexpcategory-delete.component";
import {LabExpCategoryListComponent} from "./labexpcategory-list/labexpcategory-list.component";
import {LabExpCategoryManageComponent} from "./labexpcategory-manage/labexpcategory-manage.component";
import {LabExpCategorySelectModule} from "./labexpcategory-select.module";
import {CdkTableModule} from "@angular/cdk/table";
import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {BsacApiConstantsModule, BsacFormsModule, BsacModelListModule} from "@solidev/bsadmincomponents";

@NgModule({
  imports: [
    LabExpCategorySelectModule,
    CommonModule,
    CdkTableModule,
    NgbModule,
    BsacApiConstantsModule,
    BsacFormsModule,
    BsacModelListModule,
    RouterModule,
    ReactiveFormsModule
  ],
  providers: [],
  declarations: [
    LabExpCategoryCreateComponent,
    LabExpCategoryListComponent,
    LabExpCategoryDeleteComponent,
    LabExpCategoryManageComponent
  ],
  exports: [
    LabExpCategoryCreateComponent,
    LabExpCategoryListComponent,
    LabExpCategoryDeleteComponent,
    LabExpCategoryManageComponent,
    LabExpCategorySelectModule
  ]
})
export class LabExpCategoryModule {
}
