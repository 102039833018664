import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSForeignKeyField,
  DSIntegerField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider,
  DSTextField
} from "@solidev/ngdataservice";
import {TemplatePartField} from "../../../..";


export class TemplatePart extends DSModel {
  @DSAutoField({
    name: "id",
    verboseName: "ID",
    required: false,
    helpText: "",
    choices: []
  })
  public id: number;

  @DSForeignKeyField({
    name: "template",
    verboseName: "Template ID",
    required: false,
    helpText: "Template ID",
    relatedModel: "Template",
    remoteField: "id",
    relatedName: "parts"
  })
  public template: number;


  @DSCharField({
    name: "name",
    verboseName: "Part name",
    required: false,
    helpText: "Template part name",
    minLength: 0,
    maxLength: 200,
    choices: []
  })
  public name: string;

  @DSTextField({
    name: "source",
    verboseName: "Source",
    required: false,
    helpText: "Source du template",
    minLength: 0,
    maxLength: 300000,
    choices: []
  })
  public source: string;

  @DSTextField({
    name: "style",
    verboseName: "Style",
    required: false,
    helpText: "Style du template",
    minLength: 0,
    maxLength: 300000,
    choices: []
  })
  public style: string;

  @DSTextField({
    name: "processor",
    verboseName: "Processeur",
    required: false,
    helpText: "Processor",
    minLength: 0,
    maxLength: 1000,
    choices: []
  })
  public processor: string;

  @DSIntegerField({
    name: "order",
    verboseName: "Ordre",
    required: false,
    helpText: "Ordre du fragment",
    default: 0,
    choices: []
  })
  public order: number;


  public fields_details: TemplatePartField[];
}

@Injectable({
  providedIn: "root"
})
export class TemplatePartService extends DSRestCollection<TemplatePart> {
  public adapter_config = {basePath: "/api/v1/templateparts"};
  public model = TemplatePart;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
