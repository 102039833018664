import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSDateTimeField,
  DSDefaultField,
  DSForeignKeyField,
  DSIntegerField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider,
  DSTextField
} from "@solidev/ngdataservice";
import {getIcon} from "../../common/mimetypes";

export class LabItemMedia extends DSModel {
  @DSAutoField({
    name: "id",
    verboseName: "ID",
    required: false,
    helpText: "",
    choices: []
  })
  public id: number;

  @DSForeignKeyField({
    name: "lab",
    verboseName: "Labo",
    required: false,
    helpText: "",
    choices: []
  })
  public lab: number;

  @DSForeignKeyField({
    name: "item",
    verboseName: "Matériel",
    required: false,
    helpText: "",
    choices: []
  })
  public item: number;

  @DSCharField({
    name: "filename",
    verboseName: "Nom du fichier",
    required: false,
    helpText: "",
    choices: []
  })
  public filename: string;

  @DSCharField({
    name: "title",
    verboseName: "",
    required: false,
    helpText: "",
    choices: []
  })
  public title: string;

  @DSTextField({
    name: "description",
    verboseName: "Description longue",
    required: false,
    helpText: "",
    choices: []
  })
  public description: string;

  @DSDefaultField({
    name: "tags",
    verboseName: "Tags",
    required: false,
    helpText: "",
    choices: []
  })
  public tags: any;

  @DSCharField({
    name: "author",
    verboseName: "Auteur",
    required: false,
    helpText: "",
    choices: []
  })
  public author: string;

  @DSCharField({
    name: "copyright",
    verboseName: "Copyright",
    required: false,
    helpText: "",
    choices: []
  })
  public copyright: string;

  @DSDefaultField({
    name: "file",
    verboseName: "File url",
    required: false,
    helpText: "",
    choices: []
  })
  public file: any;

  @DSCharField({
    name: "mimetype",
    verboseName: "Mime type",
    required: false,
    helpText: "",
    choices: []
  })
  public mimetype: string;

  @DSIntegerField({
    name: "filesize",
    verboseName: "Taille fichier",
    required: false,
    helpText: "",
    choices: []
  })
  public filesize: number;

  @DSTextField({
    name: "comments",
    verboseName: "Commentaires",
    required: false,
    helpText: "",
    choices: [],
    maxLength: 1000
  })
  public comments: string;

  @DSDateTimeField({
    name: "created",
    verboseName: "Date de création",
    required: false,
    helpText: "",
    choices: []
  })
  public created: Date;

  @DSDateTimeField({
    name: "updated",
    verboseName: "Date de mise à jour",
    required: false,
    helpText: "",
    choices: []
  })
  public updated: Date;

  @DSCharField({
    name: "status",
    verboseName: "Etat",
    required: true,
    helpText: "",
    choices: [["OK", "OK"], ["KO", "Pas OK"]],
    maxLength: 4
  })
  public status: string;

  @DSCharField({
    name: "orig_id",
    verboseName: "ID origine",
    required: false,
    helpText: "",
    choices: []
  })
  public orig_id: string;

  public get icon(): string {
      return getIcon(this.mimetype, this.filename);
  }

  public get isImage(): boolean {
    return this.mimetype && this.mimetype.search("image") === 0;
  }

  public get downloadUrl(): string {
    return this.file.replace(/\.[^/.]+$/, "") + "/" + this.filename;
  }
}

@Injectable({
  providedIn: "root"
})
export class LabItemMediaService extends DSRestCollection<LabItemMedia> {
  public adapter_config = {basePath: "/api/v1/labs/${context.lab}/items/${context.labitem}/medias"};
  public model = LabItemMedia;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
