import {LabProvider, LabProviderService} from "./labprovider.service";
import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {Observable} from "rxjs";

@Injectable({
  providedIn: "root"
})
export class LabProviderResolve implements Resolve<LabProvider> {
  constructor(private _model: LabProviderService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<LabProvider> {
    return this._model.get(+route.params["labproviderId"],
      {context: {lab: +route.params["labId"]}});
  }
}
