import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSForeignKeyField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider,
  DSTextField
} from "@solidev/ngdataservice";


export class TemplatePartField extends DSModel {
  @DSAutoField({
    name: "id",
    verboseName: "ID",
    required: false,
    helpText: "",
    choices: []
  })
  public id: number;


  @DSCharField({
    name: "name",
    verboseName: "Nom du champ",
    required: false,
    helpText: "Nom du champ de template",
    minLength: 0,
    maxLength: 200,
    choices: []
  })
  public name: string;

  @DSTextField({
    name: "description",
    verboseName: "Description",
    required: false,
    helpText: "Description",
    minLength: 0,
    maxLength: 1000,
    choices: []
  })
  public description: string;

  @DSCharField({
    name: "type",
    verboseName: "Type de champ",
    required: false,
    helpText: "Type de champ",
    minLength: 0,
    maxLength: 20,
    choices: []
  })
  public type: string;


  @DSForeignKeyField({
    name: "template_part",
    verboseName: "Portion de template",
    required: false,
    helpText: "Portion de template",
    relatedModel: "TemplatePart",
    remoteField: "id",
    relatedName: "fields"
  })
  public template_part: number;

  @DSTextField({
    name: "txt_value",
    verboseName: "Text value",
    required: false,
    helpText: "",
    minLength: 0,
    maxLength: 2000000,
    choices: []
  })
  public txt_value: string;

}

@Injectable({
  providedIn: "root"
})
export class TemplatePartFieldService extends DSRestCollection<TemplatePartField> {
  public adapter_config = {basePath: "/api/v1/templatepartfields"};
  public model = TemplatePartField;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
