import {LabStorage, LabStorageService} from "./labstorage.service";
import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {Observable} from "rxjs";

@Injectable({
  providedIn: "root"
})
export class LabStorageResolve implements Resolve<LabStorage> {
  constructor(private _model: LabStorageService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<LabStorage> {
    return this._model.get(+route.params.labstorageId,
      {context: {lab: +route.params.labId}});
  }
}
