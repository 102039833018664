import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSForeignKeyField,
  DSIntegerField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider
} from "@solidev/ngdataservice";


export class Section extends DSModel {
  @DSAutoField({
    name: "id",
    verboseName: "ID",
    required: false,
    helpText: "",
    choices: []
  })
  public id: number;


  @DSCharField({
    name: "name",
    verboseName: "Nom de la section",
    required: false,
    helpText: "",
    minLength: 0,
    maxLength: 200,
    choices: []
  })
  public name: string;

  @DSForeignKeyField({
    name: "document",
    verboseName: "Document",
    required: false,
    helpText: "",
    relatedModel: "Document",
    remoteField: "id",
    relatedName: "sections"
  })
  public document: number;

  @DSForeignKeyField({
    name: "template",
    verboseName: "Modèle",
    required: false,
    helpText: "Modéle",
    relatedModel: "Template",
    remoteField: "id",
    relatedName: "sections"
  })
  public template: number;

  @DSIntegerField({
    name: "order",
    verboseName: "Ordre",
    required: false,
    helpText: "Ordre de la section dans le document",
    default: 0,
    choices: []
  })
  public order: number;


}

@Injectable({
  providedIn: "root"
})
export class SectionService extends DSRestCollection<Section> {
  public adapter_config = {basePath: "/api/v1/sections"};
  public model = Section;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
