import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSDateTimeField,
  DSForeignKeyField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider
} from "@solidev/ngdataservice";

export class Token extends DSModel {
  @DSAutoField()
  public id: number;
  @DSCharField()
  public token: string;
  @DSForeignKeyField()
  public site: number;
  @DSCharField({many: true})
  public scopes: string[];
  @DSDateTimeField()
  public expire: Date;
  @DSCharField()
  public ttype: string;
}

@Injectable({
  providedIn: "root"
})
export class TokenService extends DSRestCollection<Token> {
  public adapter_config = {basePath: "/api/v1/tokens"};
  public model = Token;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
