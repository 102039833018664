import {LabCategoryCreateComponent} from "./labcategory-create/labcategory-create.component";
import {LabCategoryDeleteComponent} from "./labcategory-delete/labcategory-delete.component";
import {LabCategoryListComponent} from "./labcategory-list/labcategory-list.component";
import {LabCategoryManageComponent} from "./labcategory-manage/labcategory-manage.component";
import {LabCategorySelectModule} from "./labcategory-select.module";
import {CdkTableModule} from "@angular/cdk/table";
import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {BsacApiConstantsModule, BsacFormsModule, BsacModelListModule} from "@solidev/bsadmincomponents";

@NgModule({
  imports: [
    LabCategorySelectModule,
    CommonModule,
    CdkTableModule,
    NgbModule,
    BsacApiConstantsModule,
    BsacFormsModule,
    BsacModelListModule,
    RouterModule,
    ReactiveFormsModule
  ],
  providers: [],
  declarations: [
    LabCategoryCreateComponent,
    LabCategoryListComponent,
    LabCategoryDeleteComponent,
    LabCategoryManageComponent
  ],
  exports: [
    LabCategoryCreateComponent,
    LabCategoryListComponent,
    LabCategoryDeleteComponent,
    LabCategoryManageComponent,
    LabCategorySelectModule
  ]
})
export class LabCategoryModule {
}
