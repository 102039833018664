import {CdkTableModule} from "@angular/cdk/table";
import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {BsacApiConstantsModule, BsacFormsModule, BsacModelListModule} from "@solidev/bsadmincomponents";
import {LabItemLogsManageComponent} from "./labitemlogs-manage/labitemlogs-manage.component";
import {LabItemLogCreateComponent} from "./labitemlog-create/labitemlog-create.component";
import {LabItemLogManageComponent} from "./labitemlog-manage/labitemlog-manage.component";

@NgModule({
  imports: [
    CommonModule,
    CdkTableModule,
    NgbModule,
    BsacApiConstantsModule,
    BsacFormsModule,
    BsacModelListModule,
    RouterModule,
    ReactiveFormsModule
  ],
  providers: [],
  declarations: [LabItemLogsManageComponent, LabItemLogManageComponent, LabItemLogCreateComponent],
  exports: [LabItemLogsManageComponent, LabItemLogManageComponent, LabItemLogCreateComponent]
})
export class LabItemLogModule {
}
