import {Injectable} from "@angular/core";
import {
  DSAutoField,
  DSCharField,
  DSDateTimeField,
  DSDecimalField,
  DSDefaultField,
  DSForeignKeyField,
  DSModel,
  DSRestCollection,
  DSRestCollectionSetupProvider,
  DSTextField
} from "@solidev/ngdataservice";

export class LabItemStorage extends DSModel {
  @DSAutoField({
    name: "id",
    verboseName: "ID",
    required: false,
    helpText: "",
    choices: []
  })
  public id: number;

  @DSForeignKeyField({
    name: "lab",
    verboseName: "Labo",
    required: false,
    helpText: "",
    choices: []
  })
  public lab: number;

  @DSForeignKeyField({
    name: "item",
    verboseName: "Matériel",
    required: false,
    helpText: "",
    choices: []
  })
  public item: number;

  @DSForeignKeyField({
    name: "storage",
    verboseName: "Rangement",
    required: false,
    helpText: "",
    choices: []
  })
  public storage: number;

  @DSDefaultField({
    name: "storage_details",
    verboseName: "Rangement",
    required: false,
    helpText: "",
    choices: []
  })
  public storage_details: any;

  @DSDecimalField({
    name: "quantity",
    verboseName: "Quantité",
    required: false,
    helpText: "",
    choices: [],
    factor: 1000
  })
  public quantity: number;

  @DSForeignKeyField({
    name: "unit",
    verboseName: "Unité",
    required: false,
    helpText: "",
    choices: []
  })
  public unit: number;

  @DSDefaultField({
    name: "unit_details",
    verboseName: "Unité",
    required: false,
    helpText: "",
    choices: []
  })
  public unit_details: any;

  @DSTextField({
    name: "comments",
    verboseName: "Commentaires",
    required: false,
    helpText: "",
    choices: [],
    maxLength: 1000
  })
  public comments: string;

  @DSDateTimeField({
    name: "created",
    verboseName: "Date de création",
    required: false,
    helpText: "",
    choices: []
  })
  public created: Date;

  @DSDateTimeField({
    name: "updated",
    verboseName: "Date de mise à jour",
    required: false,
    helpText: "",
    choices: []
  })
  public updated: Date;

  @DSCharField({
    name: "status",
    verboseName: "Etat",
    required: true,
    helpText: "",
    choices: [["OK", "OK"], ["KO", "Pas OK"]],
    maxLength: 4
  })
  public status: string;

  @DSCharField({
    name: "orig_id",
    verboseName: "ID origine",
    required: false,
    helpText: "",
    choices: []
  })
  public orig_id: string;
}

@Injectable({
  providedIn: "root"
})
export class LabItemStorageService extends DSRestCollection<LabItemStorage> {
  public adapter_config = {basePath: "/api/v1/labs/${context.lab}/items/${context.labitem}/storages"};
  public model = LabItemStorage;

  constructor(public setup: DSRestCollectionSetupProvider) {
    super(setup);
  }
}
