import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {BsacFormsModule} from "@solidev/bsadmincomponents";
import {LabProviderSelectComponent} from "./labprovider-select/labprovider-select.component";

@NgModule({
  imports: [CommonModule, NgbModule, BsacFormsModule],
  declarations: [LabProviderSelectComponent],
  exports: [LabProviderSelectComponent]
})
export class LabProviderSelectModule {}
