import {LabCreateComponent} from "./lab-create/lab-create.component";
import {LabDeleteComponent} from "./lab-delete/lab-delete.component";
import {LabListComponent} from "./lab-list/lab-list.component";
import {LabManageComponent} from "./lab-manage/lab-manage.component";
import {LabSelectModule} from "./lab-select.module";
import {CdkTableModule} from "@angular/cdk/table";
import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {BsacApiConstantsModule, BsacFormsModule, BsacModelListModule} from "@solidev/bsadmincomponents";

@NgModule({
  imports: [
    LabSelectModule,
    CommonModule,
    CdkTableModule,
    NgbModule,
    BsacApiConstantsModule,
    BsacFormsModule,
    BsacModelListModule,
    RouterModule,
    ReactiveFormsModule
  ],
  providers: [],
  declarations: [LabCreateComponent, LabListComponent, LabDeleteComponent, LabManageComponent],
  exports: [LabCreateComponent, LabListComponent, LabDeleteComponent, LabManageComponent, LabSelectModule]
})
export class LabModule {
}
