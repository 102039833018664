import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {BsacFormsModule} from "@solidev/bsadmincomponents";
import {LabCategorySelectComponent} from "./labcategory-select/labcategory-select.component";

@NgModule({
  imports: [CommonModule, NgbModule, BsacFormsModule],
  declarations: [LabCategorySelectComponent],
  exports: [LabCategorySelectComponent]
})
export class LabCategorySelectModule {}
