// FIXME: move this to common part

export function getIcon(mimeType: string, filename: string): string {
  // List of official MIME Types: http://www.iana.org/assignments/media-types/media-types.xhtml
  const icon_classes = {
    // Media
    image: "fa-file-image",
    audio: "fa-file-audio",
    video: "fa-file-video",
    // Documents
    "application/pdf": "fa-file-pdf",
    "application/msword": "fa-file-word",
    "application/vnd.ms-word": "fa-file-word",
    "application/vnd.oasis.opendocument.text": "fa-file-word",
    "application/vnd.openxmlformatsfficedocument.wordprocessingml":
      "fa-file-word",
    "application/vnd.ms-excel": "fa-file-excel",
    "application/vnd.openxmlformatsfficedocument.spreadsheetml":
      "fa-file-excel",
    "application/vnd.oasis.opendocument.spreadsheet": "fa-file-excel",
    "application/vnd.ms-powerpoint": "fa-file-powerpoint",
    "application/vnd.openxmlformatsfficedocument.presentationml":
      "fa-file-powerpoint",
    "application/vnd.oasis.opendocument.presentation": "fa-file-powerpoint",
    "text/plain": "fa-file-text",
    "text/html": "fa-file-code",
    "application/json": "fa-file-code",
    // Archives
    "application/gzip": "fa-file-archive",
    "application/zip": "fa-file-archive"
  };

  const extensions = {
    ".odp": "fa-file-powerpoint"
  };

  for (const key in icon_classes) {
    if (icon_classes.hasOwnProperty(key)) {
      if (mimeType.search(key) === 0) {
        return icon_classes[key];
      }
    }
  }

  // Not found, using filename
  const ext = filename.substr(filename.lastIndexOf("."), 5);
  return extensions[ext] || "fa-file-alt";
}
