import {LabStorageCreateComponent} from "./labstorage-create/labstorage-create.component";
import {LabStorageDeleteComponent} from "./labstorage-delete/labstorage-delete.component";
import {LabStorageListComponent} from "./labstorage-list/labstorage-list.component";
import {LabStorageManageComponent} from "./labstorage-manage/labstorage-manage.component";
import {LabStorageSelectModule} from "./labstorage-select.module";
import {CdkTableModule} from "@angular/cdk/table";
import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {BsacApiConstantsModule, BsacFormsModule, BsacModelListModule} from "@solidev/bsadmincomponents";

@NgModule({
  imports: [
    LabStorageSelectModule,
    CommonModule,
    CdkTableModule,
    NgbModule,
    BsacApiConstantsModule,
    BsacFormsModule,
    BsacModelListModule,
    RouterModule,
    ReactiveFormsModule
  ],
  providers: [],
  declarations: [
    LabStorageCreateComponent,
    LabStorageListComponent,
    LabStorageDeleteComponent,
    LabStorageManageComponent
  ],
  exports: [
    LabStorageCreateComponent,
    LabStorageListComponent,
    LabStorageDeleteComponent,
    LabStorageManageComponent,
    LabStorageSelectModule
  ]
})
export class LabStorageModule {
}
